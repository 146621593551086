/* ResponsiveTable.css */

/* Estilos gerais para o container da tabela */
.table-container {
  overflow-x: auto; /* Adiciona scroll horizontal em telas pequenas */
  margin: 20px 0;
}

/* Estilos para a tabela */
table {
  width: 100%;
  border-collapse: collapse;
  margin: 0;
}

table, th, td {
  border: 1px solid #ddd;
}

th, td {
  padding: 12px;
  text-align: left;
}

th {
  background-color: #f4f4f4;
  cursor: pointer; /* Indica que o cabeçalho é clicável */
  user-select: none; /* Evita seleção de texto ao clicar */
}

th:hover {
  background-color: #e0e0e0; /* Efeito hover para o cabeçalho */
}

/* Estilos para telas pequenas */
@media screen and (max-width: 600px) {
  table {
    display: block;
  }

  thead {
    display: none; /* Esconde o cabeçalho em telas pequenas */
  }

  tbody {
    display: block;
  }

  tr {
    display: block;
    margin-bottom: 10px;
    border: 1px solid #ddd;
  }

  td {
    display: block;
    text-align: right;
    padding-left: 50%;
    position: relative;
  }

  td::before {
    content: attr(data-label);
    position: absolute;
    left: 10px;
    width: 50%;
    padding-right: 10px;
    text-align: left;
    font-weight: bold;
  }
}

/* Estilos para os controles de paginação e itens por página */
.pagination-controls {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pagination-controls label {
  margin-right: 10px;
  font-weight: bold;
}

.pagination-controls select {
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
}

/* Estilos para a paginação */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
  border: 1px solid #ddd;
  background-color: #f4f4f4;
  border-radius: 4px;
  font-size: 14px;
}

.pagination button:hover {
  background-color: #e0e0e0; /* Efeito hover para os botões */
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  opacity: 0.6;
}

.pagination span {
  margin: 0 10px;
  font-size: 14px;
}

/* Estilos para o ícone de ordenação */
th span {
  margin-left: 5px;
  font-size: 12px;
}