body {
  margin: 0;
  font-family: 'Roboto', sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


}
.container-card-relatorio{
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 25px rgba(160, 160, 160, 0.2);
  padding: 10px;

}
.fontBlack{
  color:'#000';
}
.fontRed{
  color: "#f00";
}
.container-card-relatorio-text{
  min-height: 100px;
}
.container-card-relatorio hr{
  margin-top: 10px;
}
.container-card-relatorio-icon{
  display: flex;
  align-content: center;
  justify-content: space-between;
  padding-top: 20px;
}
.reportDisabled{
  opacity: 0.2;
}
.container-card-icon{
  width: 45%;
  min-height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.container-card-icon img{
  width: 90%;
}

.container-card-icon img:hover{
  cursor: pointer;
  width: 95%;
}

.MuiDrawer-root{

}
/*fbfbfb
f8f9fa
eef4fb
eef4fb
*/
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.MuiAppBar-positionStatic{
  background-color: #fff!important;
  color:#424242!important;
}
.MuiAccordionSummary-root{
  background-color: #fff!important;
}
.MuiButtonBase-root, .MuiInputBase-root, .swal2-confirm, .swal2-cancel, .swal2-ok, .PrivateSwitchBase-input-22{
  border-radius: 0px!important;
  

}
.MuiFab-primary{
  border-radius: 50%!important;
}
.MuiTableRow-root{
  cursor:pointer !important;
}
.MuiFormHelperText-root{
  font-size: 9px!important;
}
.em-breve{
  padding-left: 5px!important;
  width: 100px!important;
  border-radius: 5px!important;
}
.contanainer-inventory{
  display: flex;
  width: 100%;
  border-radius: 5px;
  background-color: #fff;
  margin-top: 10px;
  padding: 10px;
}
.contanainer-inventory .contanainer-inventory-icon{
  width: 120px;
  padding: 5px;
}
.contanainer-inventory .contanainer-inventory-icon .computadorLigado{
  width: 60px;
  height: 60px;
  color: green;
}

.contanainer-inventory .contanainer-inventory-icon .computadorDesligado {
  width: 60px;
  height: 60px;
}
.contanainer-inventory .contanainer-inventory-body{

}