body{
   
    
    font-family: 'Roboto', sans-serif!important;
}
#template-home-container{
    background-color: #fff!important;
}
.itemMenuItem{
    color: #fff;                

    width: 96%;
 
 } 
ul {
    margin: 0;
    padding: 0;
    list-style: none;
    width: 190px;
    
    }
    ul li {
        position: relative;
       
        }
        
        li ul {
           
            position: absolute;
            left: 189px;
            width: 210px;
            display: none;
            background: #CFD8DC;
            box-shadow: 10px 5px 20px #888;
            border: none!important;
            border-radius: 0px 5px 5px 0px;
            border-top: solid 1px #000;
            border-right: solid 1px #000;
            border-bottom: solid 1px #000;
            padding: 5px;
            padding-left: 2px;
            padding-right: 2px;
            z-index: 9999999999999999;
            color: #37474F;
            
        }
        

            
            ul li a {
                display: block;
                text-decoration: none;
                color: #444;
                padding-top: 2px;
                padding-bottom: 4px;
                
               
             }
             ul li svg {
                font-size: 18px!important;
                
               
             }
             li a:hover{
                color: #fff!important;
              
                
             }
             
             
             * html ul li { float: left; }
             * html ul li a { height: 1%; }
             
             li:hover{
                color: #fff!important;
                background-image: linear-gradient(to left, transparent, #37474F);
               
             }
             li:hover .itemMenuItem{
                color: #fff!important;
             }
             
             li:hover ul { display: block; }    
             
             .itemMenuItem:hover{
                                
               
             
             }  
                   
#template-home-container{
    display: flex;

}
#side-menu-main{
    width: 200px;

   
    height: 80vh;
}
.background-sideMenu-Revenda{
    background-image: linear-gradient(to top,transparent,  #ECEFF1,  #CFD8DC,  #90A4AE,  #546E7A,  #37474F ,  #263238 ,  #263238 ,  #263238,  #263238 ); 
    height: 99vh!important;
 
    
}
.background-topMenu-Revenda{
    
    background-color: #CFD8DC!important; 
}

.background-sideMenu-Client{
    background-image: linear-gradient(to top,transparent, #8D6E63 ); 
    
}
.background-topMenu-Client{
    background-color: #D7CCC8!important;
}
.background-sideMenu-Client{
    
}

#progress-loading{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 999999999999999999999999999;
}

#side-menu-main #side-menu-logo{
    width: 100%;
    height: 90px;
    padding: 10px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
 
}
#side-menu-main #side-menu-logo img{
   width: 90%;
}
#side-menu-main hr{
    background-image: linear-gradient(to left , transparent, #78909C);
    height: 0.2!important;

    width: 94%;
    
 }
#side-menu-main .item-menu-main{
    display: flex;
    color:#fff!important;
    text-decoration: none!important;
    width: 100%;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 2px;
    padding-right: 2px;

    transition: width 0.5s ease;
 }
 #side-menu-main .item-menu-main a{
    text-decoration: none!important;
 }
 .background-topMenu-Revenda{
    
 }
 #side-menu-main .item-menu-main:hover{
    background-image: linear-gradient(to left,#BBDEFB, transparent );
    text-decoration: none!important;
    color:#000!important;
 }

#side-menu-main #side-menu-container{
    padding: 10px;
    padding-top: 50px;
}
#container-main{
    width: 100%;
    height: 100vh;
    padding: 0px!important;
}
#top-nav-menu{
    
    background-color: #8D6E63;
    top: 0px;
    height: 28px;
    width: 100%;
    padding-top: 5px;

}


#top-nav-menu .MuiBreadcrumbs-root *{
    font-size: 12px!important;
    color: #26363e!important;

}

.div-titulo-main{
    background-color: #fff;
    min-height: 40px;
    padding: 10px;
    padding-top: 5px;
    margin-top: 0px;
    
   
}
.li-item-menu{
    width: 60px;
}
.table-dark{
    margin-top: 20px;
    border-top-left-radius: 5px!important;
    border-top-right-radius: 5px!important;
    color:#26363e!important;
    border-bottom: none!important;
    
}
.table-dark .MuiTableRow-footer{
    border-bottom: none!important;
}
.table-dark tr .MuiTypography-root{
    color:#26363e!important;
    font-size: 10px!important;
}
.table-dark thead{
    
    padding-top: 2px!important;
    padding-bottom: 5px!important;
    border: none!important;
    background-color: #26363e!important;
}



.table-dark tfoot{
    padding: 0px!important;
    padding-top: 0px!important;
    padding-bottom: 0px!important;
    border: none!important;
    
    background-color: transparent!important;
}

.table-dark tfoot .MuiTablePagination-root{
    padding: 0px!important;
 
}
.table-dark thead .MuiTypography-root{
    color:#fff!important;
    font-size: 10px!important;
}
.table-dark thead th{
    
}
.table-dark tfoot .MuiTypography-root{
    color:#26363e!important;
    font-size: 10px!important;
}
.table-dark .row-hover{

}
.table-dark tbody tr:hover{
    background-color: #CFD8DC;
}
#template-home-container .button-default{
    border: solid 0.2px #26363e;
    border-radius: 5px;
    color : #26363e!important;
    background-color: transparent!important;
}
#div-container-filter{
    position: absolute;
    width: 350px;
    z-index: 999999;
    background-color: #fff;
    box-shadow: 0px 25px 25px #999;
    border-radius:5px!important;
    padding: 10px;
    padding-bottom: 20px;
    margin-top: 11px;
 
}
#div-container-filter .icone-caret{
    position: absolute;
    margin-top: -37px;
    margin-left: -10px;
    color: #fff;
    font-size: 52px;
}
#template-home-container .button-default-selected{
    border: solid 0.2px #ccc;
    background-color: #fff!important;
    
    color : #26363e!important;
   
}
.div-flex-center{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-evenly;
}
#progress-loading{
    position: fixed;
    top:0px;
    left: 0px;
    width: 100%;
    z-index: 9999999999;
}