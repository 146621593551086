.div-flex-container{
  display: flex;
  align-content: center;
  justify-content: space-evenly;
}
#div-filtro-coletas{
  position: fixed;
  background-color: #fff;
}
.div-flex-container-child svg{
  font-size: 88px;
  color: '#C5CAE9'
}
.div-flex-container-child{
  border: solid 1px red;
  width: 24%;
}
@keyframes rotate {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(-360deg); }
  }

  .loading-spinner {
   
    animation: rotate 1s linear infinite;
    color: #00897B!important;
  }


  .fade-in {
    opacity: 0;
    animation: fadeIn 1s ease-in forwards;
  }
  
  /* CSS para fade-out */
  .fade-out {
    opacity: 1;
    animation: fadeOut 1s ease-out forwards;
  }
  
  /* Definição da animação de fade-in */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  /* Definição da animação de fade-out */
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

#demo-customized-menu1 .MuiMenu-list{
  width:  350px!important;
  padding-top: 10px!important;
  padding-bottom: 10px!important;
}
#demo-customized-menu1 .MuiPaper-root{  
margin-top: 45px!important;
min-height: 200px!important;

}
#div-filter-papercut{
    width: 360px;
    background: #fff;
    box-shadow: 10px 10px 30px #c8c8c8;
    position: absolute;
    z-index: 9999999;

    padding: 20px;
    padding-top: 10px;
    border-radius: 5px;
    padding-bottom: 5px;
    border: solid 1px #CFD8DC;
    margin-left: 0px;
}
.btn-hover-filter{
    display: block;
    border-radius: 0px!important;
    height: 100%!important;
    width: 100%;
}

#icon-caret{
    position: absolute;
    margin-top: -61px;
    margin-left: -12px;
    font-size: 62px;
    color: #fff;
}
.btn-limpar{
    background-color: #fff;
    border: solid 1px #90A4AE;
    border-radius: 5px;
    padding: 5px;

    width: 100%;
}

.btn-limpar:hover{
    background-color: #FB8C00;
    border: solid 1px #FB8C00;
    color:#fff;

}



.btn-filtrar{
    background-color: #283593!important;
    color:#fff;
    border: solid 1px #283593;
    border-radius: 5px;
    padding: 5px;
   
}
.btn-filtrar:hover{
    background-color: #283593!important;
    color:#fff;

}

.btn-default{
    background-color: #fff;
    border: solid 1px #B0BEC5;
    color:#37474F;
    border-radius: 5px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
 
   width: 100%;
}
.btn-filtered{
    background-color: #fff;
    border: solid 1px #26A69A;
    color:#26A69A;
    border-radius: 5px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
  
   
}



.icone-success:hover{
    color:#26A69A!important;
}

.icone-warning:hover{
    color:#FF8F00!important;
}

